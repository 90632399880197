import { $, rect } from "@utils/dom";
import { on, off } from "@utils/listener";
import { lerp } from "@utils/math";
import ResizeOrientation from "@utils/resize";

const ATTRACTION_X = 10; // pixels
const ATTRACTION_Y = 6; // pixels
const LAYER_FACTOR = -0.5;
const THRESHOLD = 0.3;

class AttractionButton {
  constructor(el, layerSelector) {
    this.el = el;
    this.layer = $(layerSelector, this.el);

    this._center = {x: 0, y: 0};
    this._current = {x: 0, y: 0};
    this._target = {x: 0, y: 0};
    this._hover = false;
    this._event = null;
    this._raf = null;
    this._calcRequired = true;

    this._resize = this._resize.bind(this);
    this._rollover = this._rollover.bind(this);
    this._rollout = this._rollout.bind(this);
    this._mousemove = this._mousemove.bind(this);
    this._update = this._update.bind(this);

    this.init();
  }

  init() {
    this._bindEvents();
  }
  destroy() {
    this._unbindEvents();

    if( this._raf ) cancelAnimationFrame(this._raf);

    this.el = null;
    this.layer = null;

    this._center = null;
    this._current = null;
    this._target = null;
    this._hover = null;
    this._event = null;
    this._raf = null;
    this._calcRequired = null;

    this._resize = null;
    this._rollover = null;
    this._rollout = null;
    this._mousemove = null;
    this._update = null;
  }

  _bindEvents() {
    on(this.el, 'mouseenter', this._rollover);
    on(this.el, 'mouseleave', this._rollout);
    on(this.el, 'mousemove', this._mousemove);

    ResizeOrientation.add(this._resize);
  }
  _unbindEvents() {
    off(this.el, 'mouseenter', this._rollover);
    off(this.el, 'mouseleave', this._rollout);
    off(this.el, 'mousemove', this._mousemove);

    ResizeOrientation.remove(this._resize);
  }

  _resize() { this._calcRequired = true; }
  _rollover(event) {
    this._hover = true;
    this._event = event;

    if( this._calcRequired ) {
      this._calcRequired = false;
      
      const { width, height } = rect(this.el);
      this._center.x = width * 0.5;
      this._center.y = height * 0.5;
    }

    this._update();
  }
  _rollout() {
    this._hover = false;
    this._event = null;
  }
  _mousemove(event) { this._event = event; }
  _update() {
    this._raf = null;

    if( this._hover && this._event ) {
      const { offsetX, offsetY } = this._event;
      const { x, y } = this._center;

      const px = (offsetX - x) / x;
      const py = (offsetY - y) / y;
      
      this._target.x = px * ATTRACTION_X;
      this._target.y = py * ATTRACTION_Y;
    } else {
      this._target.x = 
      this._target.y = 0;
    }

    const friction = this._hover ? 0.1 : 0.06;

    this._current.x = lerp(this._current.x, this._target.x, friction);
    this._current.y = lerp(this._current.y, this._target.y, friction);

    // check if animation is completed
    let hasFinish = false;
    if( !this._hover ) {
      const distX = Math.abs(this._target.x - this._current.x);
      const distY = Math.abs(this._target.y - this._current.y);

      hasFinish = distX <= THRESHOLD && distY <= THRESHOLD;
    }

    this.el.style.transform = `translate3d(${this._current.x}px, ${this._current.y}px, 0)`;
    this.layer.style.transform = `translate3d(${this._current.x * LAYER_FACTOR}px, ${this._current.y * LAYER_FACTOR}px, 0)`;

    if( !hasFinish ) this._raf = requestAnimationFrame(this._update);
  }
}

export default AttractionButton;
