import AttractionButton from "@components/AttractionButton";
import { $$ } from "@utils/dom";
import { touch_device } from "@utils/mobile";


export class ButtonFactory {
  constructor() {
    this._buttons = [];
    this._windmill = null;
  }

  /**
    * Plugin installation.
    */
  install(windmill) {
    this._windmill = windmill;

    windmill.on('ready', this._createButtons, this);
    windmill.on('enter', this._createButtons, this);
    windmill.on('exited', this._destroyButtons, this);
  }

  _createButtons() {
    // stop here if user is on touch device (no rollover/rollout)
    if( touch_device ) return;

    // query each buttons style
    const CTAs = this._query('cta').map(btn => new AttractionButton(btn, '.btn__label'));
    const personas = this._query('persona').map(btn => new AttractionButton(btn, '.btn__label'));
    const playbacks = this._query('playback').map(btn => new AttractionButton(btn, '.btn__icon'));
    const sliders = this._query('slider').map(btn => new AttractionButton(btn, '.btn__icon'));

    this._buttons.push( ...CTAs, ...personas, ...playbacks, ...sliders );
  }
  _destroyButtons() {
    if( !this._buttons && this._buttons.length > 0 ) return;

    this._buttons.forEach(button => button.destroy());
    this._buttons = [];
  }

  _query(style) {
    return [ ...$$(`.btn.--${style}:not(.pointer-events-none)`) ];
  }
}

export default ButtonFactory;
